import * as React from "react"
import Menu from "../components/menu"
import Footer from "../components/footer"
import { StaticImage } from "gatsby-plugin-image"
import { subtitle, title, textContainer } from "../styles"
import clsx from "clsx"
import Seo from "../components/seo"

// markup
const DetailJbPage = (props) => {
  return (
    <div> 
      <Seo />
      <Menu/>
      <div className="max-w-screen-xl mx-5 xl:mx-auto md:grid md:grid-cols-3 md:gap-8">

        <div className="max-w-screen-lg mt-12 text-center col-span-2">
          <StaticImage className="w-4/6 md:w-full"
            src="../images/jb-mockup.png"
            placeholder="blurred"
            loading="eager"
          />
        </div>

        <div className="max-w-screen-sm mt-12">
          <h1 className={ title }>
            UX/UI Design - Website Redesign
          </h1>
          <div className={ clsx(textContainer, "text-justify") }>
            <p className="mb-4">Redesign of the existing website of the Public Notary Botermans, based in Belgium. The briefing for this project was to create a professional feeling and to keep all the original information. I have made use of all the unused white space and designed a more up to date website by giving a smoother flow and including visuals to guide the users.</p>
          </div>

          <div className={ textContainer }>
            <h2 className = { subtitle }> Client </h2>
            <p className="mb-4">Notaire Botermans</p>
            <h2 className = { subtitle }> Type </h2>
            <p className="mb-4">UX/UI Design</p>
            <h2 className = { subtitle }> Year </h2>
            <p className="mb-4">2019</p>
          </div>
        </div>

        <div className="max-w-screen-xl mx-auto mt-12 w-4/6 md:w-full col-span-3">
          <StaticImage className="w-6/6 md:w-full"
            src="../images/jb-home.png"
            placeholder="blurred"
            loading="eager"
          />
        </div>

        <div className="max-w-screen-xl mx-auto mt-12 w-4/6 md:w-full col-span-3">
          <StaticImage className="w-6/6 md:w-full"
            src="../images/jb-realestate.png"
            placeholder="blurred"
            loading="eager"
          />
        </div>

        <div className="max-w-screen-xl mx-auto mt-12 w-4/6 md:w-full col-span-3">
          <StaticImage className="w-6/6 md:w-full"
            src="../images/jb-jobs.png"
            placeholder="blurred"
            loading="eager"
          />
        </div>

      </div>
      <Footer/>
    </div>
     
  
  )
}


export default DetailJbPage